<template>
  <!-- REFUND POLICY -->
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="color2 color2Text--text" dense>
              <v-toolbar-title>Refund Policy</v-toolbar-title>
            </v-toolbar>
            <loading-bar v-model="gettingPolicy"></loading-bar>
            <v-expand-transition>
              <v-card-text v-if="refundPolicy">
                <v-textarea
                  :disabled="gettingPolicy"
                  readonly filled
                  :value="refundPolicy.text"
                  hide-details
                ></v-textarea>
                <v-checkbox
                  :disabled="gettingPolicy"
                  hide-details
                  label="I understand and agree to the Refund Policy above"
                  v-model="refundPolicy.agree"
                  color="success"
                ></v-checkbox>
              </v-card-text>
              <v-card-text v-else-if="gettingPolicy">
                Loading...
                <v-progress-circular indeterminate size="48" color="color3"></v-progress-circular>
              </v-card-text>
              <v-card-text v-else class="text-center">
                <div class="title">Looks like we had trouble retrieving the refund policy.  Click the button below to try again.</div>
                <v-btn
                  color="color3 color3Text--text"
                  @click.stop="getRefundPolicy"
                >
                  Click here
                </v-btn>
              </v-card-text>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
</template>

<script>

export default {
  props: ['refundPolicyId', 'active', 'emitId'],
  data () {
    return {
      gettingPolicy: false,
      refundPolicy: null
    }
  },
  computed: {
    refundAgree () {
      return this.refundPolicy && this.refundPolicy.agree
    }
  },
  methods: {
    getRefundPolicy () {
      const id = this.refundPolicyId
      if (!id) {
        this.refundPolicy = null
        return
      }
      if (this.gettingPolicy) return
      if (this.refundPolicy && this.refundPolicy.id === id) return
      this.refundPolicy = null
      this.gettingPolicy = true
      this.$VBL.cart.refundPolicy.get(id)
        .then(r => { this.refundPolicy = r.data })
        .finally(() => {
          this.gettingPolicy = false
        })
    }
  },
  mounted () {
    this.getRefundPolicy()
  },
  watch: {
    refundPolicyId: 'getRefundPolicy',
    active: 'getRefundPolicy',
    'refundPolicy.agree': function (v) {
      this.$emit('agree-change-id', { id: this.refundPolicyId, value: v })
      this.$emit('agree-change', v)
    }
  }
}
</script>
